import React from "react";

import { MenuItem } from "../../GlobalContext";

import "./PairTableLabel.css";

interface PairTableLabelProps {
  item: MenuItem;
  onToggleMenuItem: (updatedMenuItem: MenuItem) => void;
}

const PairTableLabel: React.FC<PairTableLabelProps> = ({
  item,
  onToggleMenuItem,
}) => (
  <div className="pt-label-inner">
    <div className="pt-label-wrap" onClick={() => onToggleMenuItem(item)}>
      {!item.disabled && <span className="pt-box-enabled">■</span>}
      {item.disabled && <span className="pt-box-disabled">☐</span>}
      <div className="pt-label-text">
        {item.abbreviation ? item.abbreviation : item.name}
      </div>
    </div>
  </div>
);

export default PairTableLabel;
