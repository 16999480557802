import { createContext, useContext } from "react";
import { localData, transformData } from "./data/app-data";

export type UISettings = {
  hoist: boolean,
  hoist_traits: boolean,
  single_wine_chevron_only: boolean,
}

export const UI_DEFAULTS: UISettings = {
  "hoist": true,         // "hoist" the drink name from single-drink styles to the group level
  "hoist_traits": true,       // hoist the traits (e.g. region, varieties) as well
  "single_wine_chevron_only": true,    // for single-wine groups, only show the chevron, not the count or the icon
}

export type MenuItem = {
  name: string; // name of item, for wines this includes the producer and vintage
  abbreviation: string; // shortest version of name
  id: string;
  section: string; // section name displayed in Order Panel - combines multiple sections from API together
  selected: boolean; // item selected to be used in pairing
  checked?: boolean; // item shows as checked/unchecked on menu order page
  disabled?: boolean; // item is enabled/disabled in the pair view grid.
  saved: boolean;
  bin?: string;
  format?: string;
  type?: string; // Where item was added - menu or suggestion
};

export type Table = {
  name: string;
  active: boolean; // indicates if table is the current one being used
  state?: string; // open, closed, reset, profileChange
  createdTimestamp?: number;
  updatedTimestamp?: number; // timestamp of table close/reset/profile change
};

export type User = {
  name: string;
  abbrev: string;
  color: string;
  profileActivatedTimestamp?: number;
};

// Transformed version of api - includes fields added in UI
export type ApiData = {
  api_version: string;
  menu: Menu[];
  drink_styles: DrinkStyle[];
  drink_list: Drink[];
  layout: Section[];
  servers: User[];
};

type Section = {
    name: string;
    tables: string[];
}

type DrinkStyle = {
  id: number;
  name: string;
  type: string;
  descriptors: {} | null;
  attributes: {};
  similar: number[][];
  subtypes: string[];
};

type Menu = {
  section: string;
  dishes: Dish[];
};

type Dish = {
  id: string;
  name: string;
  description: string;
  abbreviation: string;
  pairings: number[][];
  flags: string[];
};

type Drink = {
  name: string;
  short_name: string;
  producer: string;
  vintage: string;
  appellation: string;
  extras: string[];
  special_name: string;
  varieties: string[];
  attributes: {};
  region: string;
  country: {};
  formats: (string | number)[][];
  style_id: number;
  id: string; // drink id from api
  abbreviation: string;
  menu_name: string;
  menu_name_clean: string;
};

export type GlobalContent = {
  orders: MenuItem[]; // orders for the currently selected table + server
  setOrders: (orders: MenuItem[]) => void;
  tables: Table[]; // list of open tables
  setTables: (tables: Table[]) => void;
  user: User; // currently selected user
  setUser: (user: User) => void;
  data: ApiData;
  setData: (data: ApiData) => void;
  resto: String;
  setResto: (resto: String) => void;
  uiConfig: UISettings;
  setUiConfig: (uiConfig: UISettings) => void;
};

export const DefaultState = {
  orders: [],
  setOrders: () => {},
  tables: [],
  setTables: () => {},
  user: {
    name: "Other",
    abbrev: "O",
    color: "#FFB4FC",
  },
  setUser: () => {},
  data: transformData(localData, UI_DEFAULTS),
  setData: () => {},
  resto: "Tootles",
  setResto: () => {},
  uiConfig: UI_DEFAULTS,
  setUiConfig: () => {}
};


export const AppGlobalContext = createContext<GlobalContent>(DefaultState);

export const useGlobalContext = () => useContext(AppGlobalContext);
