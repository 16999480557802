export const findOne = function (haystack, arr) {
  return arr.some(function (v) {
    return haystack.indexOf(v) >= 0;
  });
};

// true if we want to omit the section name from each drink when possible (when displaying
// drinks by section - e.g. don't repeat "Pinot Noir" for each drink in a group of Pinot Noir).
const USE_SHORT_NAME = true;

export const BOTTLE_SIZE = "750";
export const GLASS_SIZE = "150";

export const sizeNames = {
    "150" : "Glass",
    "750" : "Bottle",
    "1500" : "Magnum",
    "3000" : "3L",
    "375" : "375mL",
    "500" : "500mL"
};

// Create an annotation for an "odd" (non-glass, non-750ml) format
export const annotateOddFormat = (format) => 
    format && ![GLASS_SIZE, BOTTLE_SIZE].includes(format) ? ` (${sizeNames[format]})` : '';

// Create an annotation for a format
export const annotateFormat = (format) => format ? ` (${sizeNames[format]})` : '';

export const fullDrinkName = (drink) => {
    let name = drink.name;

    if (drink.producer) {
        name = `${name}, ${drink.producer}`;
    }

    if (drink.vintage) {
        name = `${name}, ${drink.vintage}`;
    }

    return name;
}

export const generateDrinkNames = (drink) => {
  // comma-separated list of (non-750ml, non-glass) sizes for this drink
  // XXX perhaps should show 750 as well if present alongside "odd" formats
  let odd_sizes = drink.formats
    .filter(f => ![GLASS_SIZE, BOTTLE_SIZE].includes(f[0]))
    .map(f => sizeNames[f[0]])
    .join(', ')
    
  // Menu / Order Drawer Name
  let menu_name = fullDrinkName(drink);

  // Don't append odd_sizes to menu_name because either we show the size (order drawer) or we don't care (menu)
  
  // Menu / Order Drawer Name (Clean) - used for sorting, ignores quotes
  const menu_name_clean = menu_name.replace(/["]+/g, "", "");

  // Pair Table Name
  let pair_table_name = USE_SHORT_NAME ? drink.short_name : drink.name;

  if (drink.producer) {
    pair_table_name = pair_table_name ? `${pair_table_name}, ${drink.producer}` : drink.producer;
  }

  if (drink.vintage) {
    pair_table_name = pair_table_name ? `${pair_table_name}, ${drink.vintage}` : drink.vintage;
  }
  
  if (odd_sizes) {
    pair_table_name = `${pair_table_name} (${odd_sizes})`;
  }
  
  // Abbreviation, e.g. for use in the pair table header
  // (Short name can't be used here; it needs a group of drinks by type).
  // Setting this to blank forces the headers to use the full name. XXX can we do better?
  const abbreviation = ''; 

  return {
    abbreviation,
    menu_name,
    menu_name_clean,
    pair_table_name,
  };
};

export const generateDrinkMetaData = (drink) => {
  // add meta object for quicker sorting.
  const meta = {
    bottle_bin: 0,
    bottle_price: 0,
    glass_price: 0,
  };

  drink.formats.forEach((format) => {
    // XXX assumes only one non-glass format.
    if (format[0] !== GLASS_SIZE) {
      meta.bottle_price = format[1];
      meta.bottle_bin = format[2];
    }

    if (format[0] === GLASS_SIZE) {
      meta.glass_price = format[1];
    }
  });

  return meta;
};

export const formatDrinkSubtypes = (drink_style) => {
  const updatedSubtypes = drink_style.subtypes.sort((a, b) =>
    ["Sparkling", "Dessert"].includes(a) ? 1 : -1
  );

  const cleanedSubtypes = updatedSubtypes.map((subtype) => {
    if (subtype.includes("White")) return "White";
    return subtype;
  });

  return cleanedSubtypes;
};
