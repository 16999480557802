import React, { useState } from "react";
import {
  CreateAnimation,
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonList,
  IonToolbar,
} from "@ionic/react";

import { Table, useGlobalContext } from "../../GlobalContext";

import "./TableDrawer.css";

import { set } from "../../data/IonicStorage";
import useWindowDimensions from "../../hooks/WindowDimensions";

const TableDrawer: React.FC = () => {
  const { tables, setTables, data } = useGlobalContext();
  const { width } = useWindowDimensions();

  const [tab, setTab] = useState({
    name: data.layout[0].name,
    index: 0,
  });
  const [selectedTables, setSelectedTables] = useState<string[]>([]);

  const getAnimationFromTo = () => {
    if (width <= 700) {
      return [
        {
          property: "transform",
          fromValue: "translateX(400px)",
          toValue: "translateX(0px)",
        },
      ];
    } else {
      return [
        {
          property: "transform",
          fromValue: "translateY(400px)",
          toValue: "translateY(0px)",
        },
      ];
    }
  };

  const getUnselectedTables = (tabTables: string[]) => {
    const ut = tabTables.filter(
      (t) => tables.find((t1) => t1.name === t) === undefined
    );
    return ut;
  };

  const updateTable = (table: string) => {
    var updatedTables;
    if (selectedTables.includes(table)) {
      updatedTables = selectedTables.filter((t) => t !== table);
    } else {
      updatedTables = selectedTables.concat(table);
    }

    setSelectedTables(updatedTables);
  };

  const openTables = () => {
    var openedTables: Table[] = selectedTables.map((t) => {
      return { name: t, active: false };
    });

    var newTables = openedTables.concat(tables).map((t, index) => {
      if (index === 0) {
        return {
          ...t,
          active: true,
          state: "open",
          createdTimestamp: new Date().getTime(),
          updatedTimestamp: new Date().getTime(),
        };
      }
      return {
        ...t,
        active: false,
        state: "open",
        createdTimestamp: new Date().getTime(),
        updatedTimestamp: new Date().getTime(),
      };
    });

    set("tables", newTables);
    setTables(newTables);
  };

  return (
    <CreateAnimation duration={300} play={true} fromTo={getAnimationFromTo()}>
      <div className="tables-header">
        Open Table
        <IonButton
          fill="clear"
          class="clear-selection"
          onClick={() => setSelectedTables([])}
        >
          Clear selection
        </IonButton>
      </div>

      <IonContent>
        <div className="fixed-content">
          <div className="content-drawer">
            <div className="tab-column">
              {data.layout.map((tableSection, index) => {
                return (
                  <IonButton
                    key={"table-tab-" + index}
                    onClick={() =>
                      setTab({
                        name: tableSection.name,
                        index: index,
                      })
                    }
                    fill="clear"
                    expand="full"
                    class="tab"
                    className={`tab ${
                      tab.name === tableSection.name ? "active-tab" : ""
                    }`}
                  >
                    <span className="tab-name">{tableSection.name}</span>
                  </IonButton>
                );
              })}
            </div>

            <div className="values-column">
              <IonList class="item-list">
                {getUnselectedTables(data.layout[tab.index].tables).map(
                  (table, index) => {
                    return (
                      <IonItem
                        key={tab.name + index}
                        lines="none"
                        class="checkbox-label"
                      >
                        <IonLabel>{table}</IonLabel>
                        <IonCheckbox
                          onClick={() => updateTable(table)}
                          mode="md"
                          class="checkbox"
                          slot="start"
                          checked={selectedTables.some((t) => t === table)}
                        ></IonCheckbox>
                      </IonItem>
                    );
                  }
                )}
              </IonList>
            </div>
          </div>
        </div>
      </IonContent>

      <IonFooter class="drawer-footer">
        <IonToolbar class="drawer-footer-toolbar">
          <IonButton
            fill="outline"
            class="footer-buttons cancel"
            routerLink={tables.length < 1 ? "/no-tables" : "/pairing"}
          >
            Cancel
          </IonButton>
          <IonButton
            type="submit"
            class="footer-buttons save"
            routerLink="/pairing"
            disabled={selectedTables.length < 1}
            onClick={() => openTables()}
          >
            Open
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </CreateAnimation>
  );
};

export default TableDrawer;
