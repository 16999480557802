import React from "react";
import FilterButton from "../FilterButton";

import "./SortToggles.css";

import iconSp from "../../assets/icon-sp.svg";
import iconWh from "../../assets/icon-wh.svg";
import iconRo from "../../assets/icon-ro.svg";
import iconRe from "../../assets/icon-re.svg";
import iconOr from "../../assets/icon-or.svg";
import iconDe from "../../assets/icon-de.svg";
import iconPriceLow from "../../assets/icon-price-low.svg";
import iconPriceMed from "../../assets/icon-price-med.svg";
import iconPriceHigh from "../../assets/icon-price-high.svg";
import iconAttrAdv from "../../assets/icon-adventuresome.svg";
import iconAttrOrg from "../../assets/icon-organic.svg";
import iconGlass from "../../assets/icon-glass.svg";
import iconBottle from "../../assets/icon-bottle.svg";

interface SortTogglesProps {
  activeFilters: string[];
  setActiveFilters: (filters: string[]) => void;
}

const SortToggles: React.FC<SortTogglesProps> = ({
  activeFilters,
  setActiveFilters,
}) => {
  const updateFilter = (name: string) => {
    var updatedFilters: string[] = [];
    if (activeFilters.includes(name)) {
      // Remove filter
      updatedFilters = activeFilters.filter((toggle) => toggle !== name);
    } else {
      // Add filter
      updatedFilters = activeFilters.concat(name);
    }

    setActiveFilters(updatedFilters);
  };

  return (
    <div className="filters">
      <div className="filtersType">
        <FilterButton
          icon={iconSp}
          alt="sparkling"
          active={activeFilters.includes("type-sp")}
          onClick={() => updateFilter("type-sp")}
        />
        <FilterButton
          icon={iconWh}
          alt="white"
          active={activeFilters.includes("type-wh")}
          onClick={() => updateFilter("type-wh")}
        />
        <FilterButton
          icon={iconRo}
          alt="rose"
          active={activeFilters.includes("type-ro")}
          onClick={() => updateFilter("type-ro")}
        />
        <FilterButton
          icon={iconRe}
          alt="red"
          active={activeFilters.includes("type-re")}
          onClick={() => updateFilter("type-re")}
        />
        <FilterButton
          icon={iconOr}
          alt="orange"
          active={activeFilters.includes("type-or")}
          onClick={() => updateFilter("type-or")}
        />
        <FilterButton
          icon={iconDe}
          alt="dessert"
          active={activeFilters.includes("type-de")}
          onClick={() => updateFilter("type-de")}
        />
      </div>
      <div className="filtersPrice">
        <FilterButton
          icon={iconPriceLow}
          alt="price low"
          active={activeFilters.includes("price-low")}
          onClick={() => updateFilter("price-low")}
        />

        <FilterButton
          icon={iconPriceMed}
          alt="price med"
          active={activeFilters.includes("price-med")}
          onClick={() => updateFilter("price-med")}
        />
        <FilterButton
          icon={iconPriceHigh}
          alt="price high"
          active={activeFilters.includes("price-high")}
          onClick={() => updateFilter("price-high")}
        />
      </div>
      <div className="filtersTrait">
        <FilterButton
          icon={iconAttrAdv}
          alt="adventurous"
          active={activeFilters.includes("attr-adv")}
          onClick={() => updateFilter("attr-adv")}
        />
        <FilterButton
          icon={iconAttrOrg}
          alt="organic"
          active={activeFilters.includes("attr-org")}
          onClick={() => updateFilter("attr-org")}
        />
      </div>
      <div className="filtersSize">
        <FilterButton
          icon={iconGlass}
          alt="by glass"
          active={activeFilters.includes("size-glass")}
          onClick={() => updateFilter("size-glass")}
        />
        <FilterButton
          icon={iconBottle}
          alt="by bottle"
          active={activeFilters.includes("size-bottle")}
          onClick={() => updateFilter("size-bottle")}
        />
      </div>
    </div>
  );
};

export default SortToggles;
