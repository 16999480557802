import React, { useState } from "react";

import { IonButton } from "@ionic/react";

import "./OrderPanel.css";
import { MenuItem, useGlobalContext } from "../../GlobalContext";
import useWindowDimensions from "../../hooks/WindowDimensions";

import { set, getTableKey } from "../../data/IonicStorage";

import iconEditOrder from "../../assets/icon-edit-order.svg";
import iconExpandOrder from "../../assets/icon-expand-order.svg";
import iconExpandOrderLg from "../../assets/icon-expand-order-lg.svg";
import iconShrinkOrder from "../../assets/icon-shrink-order.svg";
import iconShrinkOrderLg from "../../assets/icon-shrink-order-lg.svg";

import { annotateOddFormat } from "../../data/utils";

const OrderPanel: React.FC = () => {
  const { orders, setOrders, tables, data  } = useGlobalContext();
  // get the section names, which are the 'section' attribute of each element of data.menu
  const sections = data.menu.map((menu) => menu.section);
  sections.unshift("Wines");

  const [panel, setPanel] = useState("expand");

  var activeTableName = tables.find((table) => table.active)?.name;

  const { width } = useWindowDimensions();

  const updateActiveTag = (updatedItem: MenuItem) => {
    const updatedOrders = orders.map((item) => {
      if (updatedItem.id === item.id) {
        const updatedItem = {
          ...item,
          selected: !item.selected,
        };
        return updatedItem;
      }
      return item;
    });

    set(getTableKey(activeTableName), updatedOrders);
    setOrders(updatedOrders);
  };

  const updateItemsInSection = (sectionName: string, allSelected: boolean) => {
    const updatedOrders = orders.map((item) => {
      if (sectionName === item.section) {
        const updatedItem = {
          ...item,
          selected: !allSelected,
        };
        return updatedItem;
      }
      return item;
    });

    set(getTableKey(activeTableName), updatedOrders);
    setOrders(updatedOrders);
  };

  const getOrderExpandIcon = (panel: string) => {
    if (panel === "shrink") {
      if (width > 700) {
        return iconShrinkOrderLg;
      }
      return iconShrinkOrder;
    } else {
      if (width > 700) {
        return iconExpandOrderLg;
      }
      return iconExpandOrder;
    }
  };

  const renderSection = (sectionName: string) => {
    const filteredOrders = orders.filter(
      (item) => item.section === sectionName
    );

    if (filteredOrders.length === 0) {
      return (
        <div
          key={"order-panel-section-" + sectionName}
          className="order-section"
        >
          <span className="section-label">{sectionName}</span>
          <p className="no-results section-content">None</p>
        </div>
      );
    }

    const allSelected = filteredOrders.every((item) => item.selected);

    return (
      <div key={"order-panel-section-" + sectionName} className="order-section">
        <div className="panel-section-header">
          <span className="section-label">{sectionName}</span>
          <IonButton
            key={"select-all-" + sectionName}
            class="tag-toggle"
            fill="clear"
            size="small"
            onClick={() => updateItemsInSection(sectionName, allSelected)}
          >
            {allSelected ? "Deselect All" : "Select All"}
          </IonButton>
        </div>

        <div className="section-content tag-content">
          {filteredOrders.map((filteredItem) => (
            <IonButton
              key={"tag-" + filteredItem.id}
              onClick={() => updateActiveTag(filteredItem)}
              className={`item-tag ${
                filteredItem.selected ? "active-tag" : ""
              }`}
              fill="outline"
            >
              <div className="tag-text">
                {filteredItem.name}{annotateOddFormat(filteredItem.format)}{" "}
                <span className="bin-label">{filteredItem.bin}</span>
              </div>
            </IonButton>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="order-drawer">
        <div className="order-panel-icon">
          <span className="active-table">{activeTableName}</span>
        </div>

        <div className="order-panel-icon" style={{ textAlign: "center" }}>
          <IonButton
            fill="clear"
            onClick={() => setPanel(panel === "shrink" ? "expand" : "shrink")}
            class="order-drawer-button"
          >
            <img src={getOrderExpandIcon(panel)} alt={panel + " order"} />
          </IonButton>
        </div>

        <div
          className="order-panel-icon align-bottom"
          style={{ textAlign: "right" }}
        >
          <IonButton
            routerLink="/menu"
            fill="clear"
            class="order-drawer-button"
          >
            <div className="order-drawer-button-right">
              <img src={iconEditOrder} alt="edit order" />
            </div>
          </IonButton>
        </div>
      </div>

      <div className={`order-panel ${panel === "shrink" ? "opened" : ""}`}>
        <div className="order-panel-content">
          {
            sections.map((section) => renderSection(section))
          }
        </div>
      </div>
    </>
  );
};

export default OrderPanel;
