import React, { useState, useEffect } from "react";
import cx from "classnames";
import {IonActionSheet, IonIcon} from "@ionic/react";
import useScreenOrientation from "react-hook-screen-orientation";
import { MenuItem, useGlobalContext } from "../../GlobalContext";
import {
  RankedDrinkStyle,
  ScoresObject,
  getFilteredDrinkStyles,
  getSortedResults,
} from "../PairView/helpers";
import PairTableHeader from "../PairTableHeader";
import PairTableLabel from "../PairTableLabel";
import PairBlock from "../PairBlock";
import SuggestionDetail from "../SuggestionDetail";

import iconOrganic from "../../assets/icon-organic.svg";
import iconAdventuresome from "../../assets/icon-adventuresome.svg";

import "./PairTable.css";
import { set, getTableKey } from "../../data/IonicStorage";

import { BOTTLE_SIZE, GLASS_SIZE } from "../../data/utils";
import {chevronDownOutline, chevronUpOutline} from "ionicons/icons";

interface PairTableProps {
  activeFilters: string[];
}

const PairTable: React.FC<PairTableProps> = ({ activeFilters }) => {
  const { orders, setOrders, tables, data, uiConfig} = useGlobalContext();
  // @ts-ignore
  var activeTableName = tables.find((table) => table.active)?.name;

  const screenOrientation = useScreenOrientation();

  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [tempDrinkItem, setTempDrinkItem] = useState<any>(null);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [rankedDrinkStyles, setRankedDrinkStyles] = useState<RankedDrinkStyle[]>([]);
  const [scores, setScores] = useState<ScoresObject>({});

  useEffect(() => {
    const selectedOrders = orders.filter((o) => o.selected);
    const { scores: scoresObj, results } = getSortedResults(
      selectedOrders,
      data
    );

    setScores(scoresObj);
    setRankedDrinkStyles(results);
  }, [orders, data]);

  const handleToggleExpand = (itemId: string) => {
    let updatedExpandedItems: string[] = [...expandedItems];

    if (expandedItems.includes(itemId)) {
      updatedExpandedItems = expandedItems.filter((id) => id !== itemId);
    } else {
      updatedExpandedItems.push(itemId);
    }

    setExpandedItems(updatedExpandedItems);
  };

  const onToggleMenuItem = (updatedItem: MenuItem) => {
    const updatedOrders = orders.map((item) => {
      if (updatedItem.id === item.id) {
        const updatedItem = {
          ...item,
          disabled: !item.disabled,
        };
        return updatedItem;
      }
      return item;
    });

    setOrders(updatedOrders);
  };

  const addDrinkToOrder = (drink: any, size?: string) => {
    const existingItem = orders.find((item) => {
      return drink.id === (item.id as string);
    });

    if (existingItem) {
      console.log(`${drink.id} already exists in order.`);
      return;
    }

    const updatedOrders: MenuItem[] = [...orders];

    if (drink.formats.length === 1 || size) {
      // Determine the bin number based on size specified and add the item
      // to the order.
      const formats: [[string, number, string]] = drink.formats;
      
      /* 
         XXX because we don't currently allow selecting anything but glass or bottle,
         allow any "bottle" to mean "not a glass," to handle odd bottle sizes. Obviously
         breaks when there is more than 1 bottle size for a drink.
      */
      const selectedFormat = size ? 
        formats.find((o) => o[0] === size || (size===BOTTLE_SIZE && o[0] !== GLASS_SIZE)) : 
        formats[0];

      if (!selectedFormat) {
        console.log(`Size option (${size}) not found on ${drink.name}`);
        return;
      }

      updatedOrders.push({
        id: drink.id,
        name: drink.menu_name,
        abbreviation: drink.abbreviation,
        section: "Wines",
        bin: selectedFormat[2],
        format: selectedFormat[0],
        selected: false,
        checked: true,
        saved: true,
        type: "suggestion",
      });

      set(getTableKey(activeTableName), updatedOrders);
      setOrders(updatedOrders);
      setTempDrinkItem(null);
    } else {
      // Multiple size options are available, prompt the user to pick one.
      setTempDrinkItem(drink);
      setShowActionSheet(true);
    }
  };

  const results: RankedDrinkStyle[] = getFilteredDrinkStyles(activeFilters, rankedDrinkStyles);

  // Order pair grid items w/ Drinks first, followed by Coursing Order.
  const selectedOrders: MenuItem[] = orders.filter((o) => o.selected);
  let sortedOrders: MenuItem[] = [];

  const sections = data.menu.map((menu) => menu.section);
  sections.unshift("Wines");
  sections.forEach((section) => {
    const sectionOrders = selectedOrders.filter(
      (o) => o.section === section
    );
    sectionOrders.forEach((o) => sortedOrders.push(o));
  });

  return (
    <>
      <div className="pt-wrap">
        <table>
          <tbody>
            <tr>
              <th>
                <PairTableHeader
                  results={results}
                  screenOrientation={screenOrientation}
                />
              </th>
              {sortedOrders.map((item, i) => {
                return (
                  <th key={i} className="pt-label-th" style={{ width: 60 }}>
                    <PairTableLabel
                      item={item}
                      onToggleMenuItem={onToggleMenuItem}
                    />
                  </th>
                );
              })}
              <th style={{ minWidth: 20, width: 20 }}>
                <div className="pt-header-gap"></div>
              </th>
            </tr>
            {results.map((result, n) => {
              const { drink_style, drinks } = result;

              const style_id = result.drink_style.id.toString();
              const isSingleDrink = drinks.length === 1;
              const hoist = uiConfig.hoist && isSingleDrink;
              const chevronOnly = uiConfig.single_wine_chevron_only && isSingleDrink;
              const isExpanded = expandedItems.includes(style_id);
              const background = result.matchesFilter ? "#191919" : "#414040";

              let isDisplayingLeafIcon = false;

              return (
                <tr
                  key={n}
                  style={{
                    background,
                  }}
                >
                  <td style={{ background }}>
                    <div
                      className="pt-suggestion-item-wrap"
                      style={{ background }}
                    >
                      <div
                        className={cx(
                          "pt-suggestion-grid",
                          screenOrientation === "landscape-primary" &&
                            "pt-suggestion-grid-landscape"
                        )}
                        onClick={() => handleToggleExpand(style_id)}
                      >
                        <div className="pt-pill-wrap">
                          <div className={cx( "pt-pill", "pt-pill-expanded" )}>
                            {drink_style.style_icon_text !== "na" && (
                              <div
                                className="type"
                                style={{
                                  backgroundColor: drink_style.style_icon_bgcolor,
                                  color: drink_style.style_icon_text_color,
                                }}
                              >
                                {drink_style.style_icon_text}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="pt-details">
                          <div className="pt-drinkstyle-header">
                            <div className="pt-drinkstyle-name">
                              {
                                hoist ? result.drinks[0].menu_name : result.drink_style.name
                              }
                            </div>
                            <div className={cx("pt-group-pill", chevronOnly && "pt-group-single") }>
                                  <div className="stock">{ chevronOnly ? "" : drinks.length} <IonIcon icon={ isExpanded ? chevronUpOutline : chevronDownOutline }></IonIcon>
                                  </div>
                            </div>
                          </div>
                          {drink_style.traits && (
                            <div className="pt-traits">
                              <ul>
                                {drink_style.traits.map((trait, index) => {
                                  // don't show member traits if the item is expanded
                                  // only show icon member traits unless hoisting.
                                  if (trait.is_member && (isExpanded || (!(hoist && uiConfig.hoist_traits) && !trait.is_icon))) {
                                    return null;
                                  }
                                  if (trait.name === "Adventuresome") {
                                    return (
                                      <img
                                        key={index}
                                        src={iconAdventuresome}
                                        alt="add item"
                                      />
                                    );
                                  }

                                  if (
                                    (trait.name === "Organic" ||
                                      trait.name === "Biodynamic") &&
                                    !isDisplayingLeafIcon
                                  ) {
                                    isDisplayingLeafIcon = true;
                                    return (
                                      <img
                                        key={index}
                                        src={iconOrganic}
                                        alt="add item"
                                      />
                                    );
                                  }

                                  if (!trait.is_icon) {
                                    return <li key={index}>{trait.name}</li>;
                                  }

                                  return null;
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div>
                          {!isExpanded && (
                            <div
                              className="pt-prices"
                              style={{ marginTop: 12 }}
                            >
                              <div
                                style={{
                                  color: result.drink_style.meta.hasGlassOption
                                    ? "#F2F2F2"
                                    : "#727272",
                                }}
                              >
                                {result.drink_style.meta.glassPriceMin
                                  ? `${result.drink_style.meta.glassPriceMin}${
                                      result.drink_style.meta.hasRangedGlassPrices
                                        ? "+"
                                        : ""
                                    }`
                                  : "NA"}
                              </div>
                              <div
                                style={{
                                  color: result.drink_style.meta.hasBottleOption
                                    ? "#F2F2F2"
                                    : "#727272",
                                }}
                              >
                                {result.drink_style.meta.bottlePriceMin
                                  ? `${result.drink_style.meta.bottlePriceMin}${
                                      result.drink_style.meta.hasRangedBottlePrices
                                        ? "+"
                                        : ""
                                    }`
                                  : "NA"}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {isExpanded && (
                        <SuggestionDetail
                          drink_style={drink_style}
                          drinks={drinks}
                          addDrinkToOrder={addDrinkToOrder}
                          hoist={hoist}
                        />
                      )}
                    </div>
                  </td>
                  {sortedOrders.map((item, x) => {
                    const score = scores[result.drink_style.id][item.id];
                    return (
                      <td key={x}>
                        <div className="pt-pair-block-wrap">
                          <PairBlock
                            score={score}
                            opacity={item.disabled ? 0.2 : 1}
                          />
                        </div>
                      </td>
                    );
                  })}
                  <td style={{ minWidth: 20, width: 20, background }}></td>
                </tr>
              );
            })}
            <PairTableFill />
          </tbody>
        </table>
      </div>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => {
          setShowActionSheet(false);
          setTempDrinkItem(null);
        }}
        cssClass="select-size"
        header="Select Size"
        buttons={[
          {
            text: "Glass",
            handler: () => {
              if (!tempDrinkItem) return;
              addDrinkToOrder(tempDrinkItem, GLASS_SIZE);
            },
          },
          {
            text: "Bottle",
            handler: () => {
              if (!tempDrinkItem) return;
              addDrinkToOrder(tempDrinkItem, BOTTLE_SIZE);
            },
          },
        ]}
      ></IonActionSheet>
    </>
  );
};

// Used to expand the divider between the suggestions list and pairing grid
// in the event that there are not enough results to fill the screen height.
const PairTableFill = () => (
  <tr className="pt-table-fill">
    <td></td>
    <td></td>
  </tr>
);

export default PairTable;
