import React from "react";
import { IonButton } from "@ionic/react";

import iconPlus from "../../assets/icon-plus.svg";

import "./TablePrompt.css";

const TablePrompt: React.FC = () => (
  <div className="table-prompt">
    <h2 className="message-header">No open tables</h2>
    <p className="message-body">Open a table to get drink suggestions</p>
    <IonButton
      key="add-table-btn"
      className="message-add-table"
      routerLink="/tables"
    >
      <img src={iconPlus} alt="add table" />
    </IonButton>
  </div>
);

export default TablePrompt;
