import React from "react";
import cx from "classnames";
import chroma from "chroma-js";

import "./PairBlock.css";

interface PairBlockProps {
  score?: number;
  opacity: number;
}

const MIN_SIDE = 10;
const MAX_SIDE = 40;
const MAX_AREA = MAX_SIDE * MAX_SIDE;

/* Green 100%, Blue 50%, Gray 0% */
const color = chroma.scale(["#9c9c9c", "#6DA3D6", "#6BE269"]);

const PairBlock: React.FC<PairBlockProps> = ({ score = 0, opacity = 1 }) => {
  const backgroundColor = color(score).toString();

  const area = Math.round(MAX_AREA * score);
  let side = Math.round(Math.sqrt(area));

  if (side < MIN_SIDE) side = MIN_SIDE;
  if (side > MAX_SIDE) side = MAX_SIDE;

  return (
    <div className="blockWrap">
      <div
        className={cx("block")}
        style={{
          width: side,
          height: side,
          backgroundColor,
          opacity,
        }}
      ></div>
    </div>
  );
};

export default PairBlock;
