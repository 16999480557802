/*
 Create a dynamic manifest.json file
 */

import manifestTemplate from './manifest-template.json';

// convert a resto and a UiConfig into a start URL
// the URL parameters are the same as the UiConfig values
export function createStartUrl(resto) {
    const url = window.location.origin + `/R/${resto}`;
    console.log(`Start URL: ${url}`);
    return url;
}

export function createAndInjectManifest(options = {}) {

    // Copy the default manifest template and add in the options
    const manifest = JSON.parse(JSON.stringify(manifestTemplate));
    Object.assign(manifest, options);

    // make all of the icon src urls absolute - browsers didn't like the relative ones
    manifest.icons = manifest.icons.map(icon => {
        icon.src = window.location.origin + icon.src;
        return icon;
    });

    manifest.scope = window.location.origin;

    // Create a blob from the manifest JSON
    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    // replace the existing manifest if it exists, or add the new one
    const existingManifest = document.querySelector('link[rel="manifest"]');
    if (existingManifest) {
        console.log('Manifest already exists, replacing');
        existingManifest.href = url;
    }
    else {
        const link = document.createElement('link');
        link.rel = 'manifest';
        link.href = url;
        document.head.appendChild(link);
    }
    console.log(`Manifest injected: ${url}`);
}