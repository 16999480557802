import axios from "axios";
import { set } from "../data/IonicStorage";

//const prefix = 'http://localhost:8000';
const prefix = '';

// Get current bearer token or returns null
export async function getTokenApi() {
  const token = await axios
    .post(
      `${prefix}/api/token/`,
      {
        username: "guest",
        password: "c0mE0n1n!",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((resp) => {
      console.log("Successfully retrieved token");
      return resp.data.access;
    })
    .catch((err) => {
      console.log("Failed to retrieve token");
      console.log(err);
      return null;
    });
  return token;
}

// Upload data to the api endpoint
// Returns true if successful, false if not successful
export async function uploadAppData(resto:String, data: any) {
  const token = await getTokenApi();

  if (!token) {
    return false;
  }

  const config = {
    method: "post",
    url: `${prefix}/api/upload/${resto}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const result = await axios(config)
    .then((resp) => {
      return resp.data.status === "success";
    })
    .catch((err) => {
      console.log("Failed to upload data");
      console.log(err);
      return false;
    });

  return result;
}

// Caches the latest data dump from the API endpoint
// If app is offline, skips caching
export async function cacheDataDump(resto: String) {
  const token = await getTokenApi();

  if (!token) {
    return false;
  }

  const config = {
    method: "post",
    url: `${prefix}/api/dump/${resto}/1/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const result = await axios(config)
    .then((resp) => {
      // Cache data
      console.log("Successfully downloaded data");
      set("apiCache", resp.data);
      return true;
    })
    .catch((err) => {
      console.log("Failed to upload data");
      console.log(err);
      return false;
    });

  return result;
}
