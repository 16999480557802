import React, { useEffect, useState } from "react";

// import axios from "axios";
import { IonApp, IonPage, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

import PairView from "./components/PairView";
import OrderView from "./components/OrderView";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Global CSS */
import "./global.css";
import "./app.css";

import {
  ApiData,
  AppGlobalContext,
  DefaultState,
  MenuItem,
  Table,
  User,
} from "./GlobalContext";
import TablePrompt from "./components/TablePrompt";
import ProfileSelector from "./components/ProfileSelector";
import TableSelector from "./components/TableSelector";
import TableDrawer from "./components/TableDrawer";

import { createStore, get, set, clear, getTableKey } from "./data/IonicStorage";
import { cacheDataDump } from "./api/DataApi";
import { getLatestData, transformData } from "./data/app-data";
import { createAndInjectManifest, createStartUrl } from "./manifest/dynamic-manifest"

enum LoadingState {
    INIT,
    LOADING,
    LOADED,
}

const App: React.FC = () => {
  const [loading, setLoading] = useState<LoadingState>(LoadingState.INIT);
  const [redirect, setRedirect] = useState(false); // redirect to no-tables

  const [orders, setOrders] = useState<MenuItem[]>(DefaultState.orders);
  const [tables, setTables] = useState<Table[]>(DefaultState.tables);
  const [user, setUser] = useState<User>(DefaultState.user);
  const [data, setData] = useState<ApiData>(DefaultState.data);
  const [resto, setResto] = useState<String>(DefaultState.resto);
  const [uiConfig,  setUiConfig] = useState(DefaultState.uiConfig);
  console.log('render App');

  useEffect(() => {
    // Check for restaurant name in initial path
    const pathComponents = window.location.pathname.slice(1,).split('/');

    // Load local storage and get restaurant name
    const setupStore = async () => {
      await createStore("awesommdb");

      let newResto = (await get("resto")) || resto;
      if (pathComponents.length > 1 && pathComponents[0]==='R') {
        // Specifying a restaurant resets the state, even if the same restaurant is specified
        console.log("Resto change");
        newResto = decodeURI(pathComponents[1]);

        clear();
        setOrders([]);
        setTables([]);
        setUser(DefaultState.user);
      }

      set("resto", newResto);
      setResto(newResto);

      // inject the dynamic manifest as soon as we have the restaurant name.
      // Chrome seems to prefer this as early as possible, or it thinks we don't have one.
      createAndInjectManifest({
        "start_url": createStartUrl(newResto),
      });
      return newResto;
    };

    const loadSavedState = async () => {
      const savedTables = await get("tables");

      if (savedTables) {
        // Use saved tables
        setTables(savedTables);
      } else {
        // Set open tables to empty
        set("tables", []);
      }

      const activeUser = await get("activeUser");
      if (activeUser) {
        setUser(activeUser);
      }
    };

    // Cache and load data from dump API
    const setupData = async () => {
      setLoading(LoadingState.LOADING);
      const useResto = await setupStore();

      await loadSavedState();
      await cacheDataDump(useResto);
      const data = await getLatestData();
      const newUiConfig = data.config;
      set("uiConfig", newUiConfig);
      setUiConfig(newUiConfig);

      setData(transformData(data, newUiConfig));

      setLoading(LoadingState.LOADED);
    };

    if (loading === LoadingState.INIT) {
      setupData();
    }
  }, [resto, uiConfig, loading]);

  useEffect(() => {
    // Set active table's orders using saved data
    console.log('getOrders, etc.')
    const getOrders = async () => {
      const activeTable = tables.find((table: Table) => table.active);

      if (activeTable) {
        // Get active table's orders if exist
        const savedOrder = await get(getTableKey(activeTable.name));

        if (savedOrder) {
          setOrders(savedOrder);
        } else {
          setOrders([]);
        }
      }
    };

    getOrders();

    const checkTables = () => {
      // Redirect to no-tables if no tables are selected
      if (loading === LoadingState.LOADED) {
        console.log('Redirect');
        setRedirect(true);
      } else {
        console.log('No redirect')
        setRedirect(false);
      }
    };

    checkTables();
  }, [tables, loading]);

  return (
    <IonApp>
      <AppGlobalContext.Provider
        value={{
          orders,
          setOrders,
          tables,
          setTables,
          user,
          setUser,
          data,
          setData,
          resto,
          setResto,
            uiConfig,
            setUiConfig
        }}
      >
        <IonReactRouter>
          <IonPage id="main">
            <div className="navbar">
              <ProfileSelector />
              <TableSelector />
            </div>

            <IonRouterOutlet class="main-content">
              <Route path="/pairing" component={PairView} />
              <Route path="/menu" component={OrderView} />
              <Route path="/tables" component={TableDrawer} />
              <Route path="/no-tables" component={TablePrompt} />
              <Redirect
                exact
                from="/"
                to={tables.length > 0 ? "pairing" : "/no-tables"}
              />
            </IonRouterOutlet>

            {redirect ? <Redirect to={tables.length > 0 ? "/pairing" : "/no-tables"} /> : null}
          </IonPage>
        </IonReactRouter>
      </AppGlobalContext.Provider>
    </IonApp>
  );
};

export default App;
