export default {
  data_updated: "2022-09-29T20:54:04.405Z",
  api_version: "0.3-local",
  config: {
    hoist: true,
    hoist_traits: true,
    single_wine_chevron_only: true,
  },
  drink_styles: [
  ],
  drink_list: [
  ],
  menu: [
  ],
  servers: [
    {
        name : "Other",
        abbrev : "O",
        color: "#FFB4FC"
    } 
  ],
  layout: [
    {
        name : "Loading",
        'tables' : [
            "1"
        ]
    }
  ]
};
