import { ApiData, MenuItem } from "../../GlobalContext";

interface DrinkStyleDescriptors {
  style: string;
  aspects: string[];
}

export interface ScoresObject {
  [styleId: string]: {
    [dishId: string]: number;
  };
}

export interface Trait {
  name: string;
  pri: number;
  is_icon: boolean;     // is this a trait that should be displayed as an icon
  is_member: boolean;   // is this a trait of a member of the group, as opposed to the group itself
}

export interface RankedDrinkStyle {
  drink_style: {
    id: number;
    name: string;
    attributes?: object;
    descriptors: DrinkStyleDescriptors | null;
    subtypes: string[];
    type: string;
    traits: Trait[];
    aspects_str: string;
    style_icon_text: string;
    style_icon_text_color: string;
    style_icon_bgcolor: string;
    meta: {
      isAdventuresome?: boolean;
      isOrganic?: boolean;
      isBiodynamic?: boolean;
      hasGlassOption?: boolean;
      hasBottleOption?: boolean;
      glassPriceMin?: number;
      glassPriceMax?: number;
      bottlePriceMin?: number;
      bottlePriceMax?: number;
      hasRangedGlassPrices?: boolean;
      hasRangedBottlePrices?: boolean;
      isLowPrice?: boolean;
      isMedPrice?: boolean;
      isHighPrice?: boolean;
    };
  };
  composite_score: number;
  filterTypes: string[];
  filterAttributes: string[];
  drinks: any[];
  matchesFilter?: boolean;
}

export const Tags: { [tag: string]: string } = {
  Sparkling: "type-sp",
  White: "type-wh",
  Rosé: "type-ro",
  Red: "type-re",
  Orange: "type-or",
  Dessert: "type-de",
  Sweet: "type-de",
  Fortified: "type-de",
  Adventuresome: "attr-adv",
  Organic: "attr-org",
};

export const TypeFilters: string[] = [
  "type-sp",
  "type-wh",
  "type-ro",
  "type-re",
  "type-or",
  "type-de",
];

interface ScoresTally {
  [name: string]: number;
}

interface SortedResults {
  scores: ScoresObject;
  results: RankedDrinkStyle[];
}

export const getSortedResults = (
  orders: MenuItem[],
  data: ApiData
): SortedResults => {
  const { menu, drink_styles, drink_list } = data;

  const tally: ScoresTally = {};
  const scores: ScoresObject = {};

  orders.forEach((item) => {
    if (item.section === "Wines") {
      const drink_id = item.id;
      const drink = drink_list.find((o) => o.id === drink_id);
      if (!drink) {
        console.log(`Unable to find drink with drink_id: ${drink_id}`);
        return;
      }

      // look up similar styles for this drink_style
      const drink_style = drink_styles.find((drink_style) => drink_style.id === drink.style_id);
      if (!drink_style) {
        console.log(`Unable to find drink with id: ${drink.style_id}`);
        return;
      }

      drink_style?.similar.forEach((pair) => {
        const styleId = pair[0].toString();
        const score = pair[1];

        if (!scores[styleId]) scores[styleId] = {};
        if (!scores[styleId][item.id]) scores[styleId][item.id] = score;
        if (!tally[styleId]) tally[styleId] = 0;

        if (!item.disabled && item.selected) {
          tally[styleId] += score;
        }
      });
    } else {
      menu.forEach((section) => {
        section.dishes.forEach((dish) => {
          if (dish.id !== item.id || !dish.pairings) return;

          const dishId = dish.id.toString();

          dish.pairings.forEach((pair) => {
            const styleId = pair[0].toString();
            const score = pair[1];

            if (!scores[styleId]) scores[styleId] = {};
            if (!scores[styleId][dishId]) scores[styleId][dishId] = score;
            if (!tally[styleId]) tally[styleId] = 0;

            // If the order item is unselected, we do not want to factor it
            // into the composite score calculation.
            if (!item.disabled && item.selected) {
              tally[styleId] += score;
            }
          });
        });
      });
    }
  });

  // 2. Sort the results (highest to lowest match)
  const items = Object.keys(tally).map((key) => {
    const result: [string, number] = [key, tally[key]];
    return result;
  });

  items.sort((a, b) => a[1] - b[1]);
  items.reverse();

  const results: RankedDrinkStyle[] = [];

  items.forEach((match) => {
    const drink_style: any = drink_styles.find((o) => o.id === parseInt(match[0]));

    const drinks = drink_list.filter((drink) => drink.style_id === drink_style.id);

    const { filterTypes, filterAttributes } = getFiltersForDrinkStyle(drink_style);

    if (drink_style)
      results.push({
        drink_style,
        composite_score: tally[drink_style.id],
        drinks,
        filterTypes,
        filterAttributes,
      });
  });

  return {
    scores,
    results,
  };
};

export const getGroupedPrice = (
  priceLow: number | string,
  priceHigh: number | string
): string => {
  if (
    typeof priceHigh === "number" &&
    typeof priceLow === "number" &&
    priceHigh > priceLow
  ) {
    return `${priceLow}+`;
  } else {
    return `${priceLow}`;
  }
};

interface RankedDrinkStyleFilters {
  filterTypes: string[];
  filterAttributes: string[];
}

export const getFiltersForDrinkStyle = (drink_style: any): RankedDrinkStyleFilters => {
  const filterTypes: string[] = [];
  const filterAttributes: string[] = [];

  if (drink_style.subtypes) {
    drink_style.subtypes.forEach((subtype: string) => {
      if (Tags[subtype]) filterTypes.push(Tags[subtype]);
    });
  }

  if (drink_style.attributes) {
    Object.keys(drink_style.attributes).forEach((attr: string) => {
      if (Tags[attr]) filterAttributes.push(Tags[attr]);
    });
  }

  return { filterTypes, filterAttributes };
};

const COLOR_FILTERS = ["type-re", "type-ro", "type-or", "type-wh"];

const matchesFilter = (
  rankedDrinkStyle: RankedDrinkStyle,
  activeFilters: string[]
): boolean => {
  if (!activeFilters.length) return false;

  const filterByPrice = !!activeFilters.find((filter) =>
    filter.includes("price")
  );
  const filterBySize = !!activeFilters.find((filter) =>
    filter.includes("size")
  );

  const filterByColor = !!activeFilters.find((filter) =>
    COLOR_FILTERS.includes(filter)
  );

  const filterByAttribute = !!activeFilters.find((filter) =>
    filter.includes("attr")
  );

  const filterSparkling = activeFilters.includes("type-sp");
  const filterDessert = activeFilters.includes("type-de");

  const {
    drink_style: {
      meta: {
        isLowPrice,
        isMedPrice,
        isHighPrice,
        isBiodynamic,
        isOrganic,
        isAdventuresome,
        hasGlassOption,
        hasBottleOption,
      },
    },
  } = rankedDrinkStyle;

  let matchPrice = false;
  let matchAttr = false;
  let matchSize = false;
  let matchColor = false;

  const isRed = rankedDrinkStyle.filterTypes.includes("type-re");
  const isRose = rankedDrinkStyle.filterTypes.includes("type-ro");
  const isWhite = rankedDrinkStyle.filterTypes.includes("type-wh");
  const isOrange = rankedDrinkStyle.filterTypes.includes("type-or");

  const isSparkling = rankedDrinkStyle.filterTypes.includes("type-sp");
  const isDessert = rankedDrinkStyle.filterTypes.includes("type-de");

  activeFilters.forEach((f) => {
    if (filterByPrice && f.includes("price")) {
      if (f === "price-low" && isLowPrice) matchPrice = true;
      else if (f === "price-med" && isMedPrice) matchPrice = true;
      else if (f === "price-high" && isHighPrice) matchPrice = true;
    }

    if (filterByAttribute && f.includes("attr")) {
      const isOrg = isOrganic || isBiodynamic;
      if (f === "attr-adv" && isAdventuresome) matchAttr = true;
      else if (f === "attr-org" && isOrg) matchAttr = true;
    }

    if (filterBySize && f.includes("size")) {
      if (f === "size-glass" && hasGlassOption) matchSize = true;
      if (f === "size-bottle" && hasBottleOption) matchSize = true;
    }

    if (filterByColor && COLOR_FILTERS.includes(f)) {
      if (
        (!filterSparkling && !filterDessert) ||
        (filterSparkling && isSparkling) ||
        (filterDessert && isDessert)
      ) {
        if (f === "type-re" && isRed) matchColor = true;
        if (f === "type-ro" && isRose) matchColor = true;
        if (f === "type-wh" && isWhite) matchColor = true;
        if (f === "type-or" && isOrange) matchColor = true;
      }
    }
  });

  if (filterSparkling && !filterByColor && !isSparkling) return false;
  if (filterDessert && !filterByColor && !isDessert) return false;
  if (filterByPrice && !matchPrice) return false;
  if (filterByAttribute && !matchAttr) return false;
  if (filterBySize && !matchSize) return false;
  if (filterByColor && !matchColor) return false;

  return true;
};

// Apply filters to full list of results.
// 1. filter by drink type (Red, Orange etc)
// 2. filter by attributes (Adventuresome, Organic)
// 3. filter by price (Low, Med, High)
// 4. filter by glass or bottle

export const getFilteredDrinkStyles = (
  activeFilters: string[],
  rankedDrinkStyles: RankedDrinkStyle[]
): RankedDrinkStyle[] => {
  const resultsMatched: RankedDrinkStyle[] = [];
  const resultsUnmatched: RankedDrinkStyle[] = [];

  rankedDrinkStyles.forEach((rankedDrinkStyle) => {
    rankedDrinkStyle.matchesFilter = matchesFilter(rankedDrinkStyle, activeFilters);

    // display items matching the filter first.
    if (rankedDrinkStyle.matchesFilter) {
      resultsMatched.push(rankedDrinkStyle);
    } else {
      resultsUnmatched.push(rankedDrinkStyle);
    }
  });

  return resultsMatched.concat(resultsUnmatched);
};

export const formatABV = (alcohol: number): string => {
  return (alcohol % 1 === 0 ? alcohol.toString() : alcohol.toFixed(1)) + "%";
};