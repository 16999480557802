import React from "react";
import { IonButton } from "@ionic/react";

import { useGlobalContext } from "../../GlobalContext";

import "./TableSelector.css";
import { set } from "../../data/IonicStorage";

import iconPlus from "../../assets/icon-plus.svg";

const TableSelector: React.FC = () => {
  const { tables, setTables } = useGlobalContext();

  const setActiveTable = (tableName: string) => {
    const updatedTables = tables.map((table) => {
      if (table.name === tableName) {
        return { ...table, active: true };
      }

      if (table.active) {
        return { ...table, active: false };
      }

      return table;
    });

    set("tables", updatedTables);
    setTables(updatedTables);
  };

  return (
    <div className="table-button-group">
      {tables.map((table) => (
        <IonButton
          key={"table-" + table.name}
          onClick={() => setActiveTable(table.name)}
          className={`table-button ${table.active ? "table-active" : ""}`}
          fill="outline"
        >
          {table.name}
        </IonButton>
      ))}

      <IonButton key="add-table-btn" className="add-table" routerLink="/tables">
        <img src={iconPlus} alt="add table" />
      </IonButton>
    </div>
  );
};

export default TableSelector;
