import dummyData from "./dummy-data";
import { get } from "./IonicStorage";
import { GLASS_SIZE } from "./utils";

import {
  fullDrinkName,
  generateDrinkNames,
  generateDrinkMetaData,
  formatDrinkSubtypes,
} from "./utils";
import {formatABV} from "../components/PairView/helpers";

export const localData = dummyData;

export const getLatestData = async () => {
  const cached = await get("apiCache");
  if (cached) {
    console.log("Using cache");
    return cached;
  }

  console.log("Using local data");
  return dummyData;
};

const WINE_COLORS = {
  Sp: "#F7E7CE",
  Wh: "#EEEDC4",
  Ro: "#FF85BF",
  Re: "#FF5A78",
  Or: "#FD834A",
  De: "#292320",
  Sw: "#292320",
  Ot: "#292320",
};

export const transformData = (dataToTransform, uiConfig) => {
  const drink_styles = dataToTransform.drink_styles.map((drink_style) => {
    const drinks = dataToTransform.drink_list.filter(
      (drink) => drink.style_id === drink_style.id
    );

    const meta = {
      glassPriceMin: 99999,
      glassPriceMax: 0,
      bottlePriceMin: 99999,
      bottlePriceMax: 0,
      hasRangedGlassPrices: false, // the drink_style has multiple glass options of varying prices
      hasRangedBottlePrices: false, // the drink_style has multiple bottle options of varying prices
      isLowPrice: false,
      isMedPrice: false,
      isHighPrice: false,
    };

    const traits = [];
    let aspects_str = "";
    let style_icon_text = "Ot";
    let style_icon_text_color = "#353b2e";
    let style_icon_bgcolor = WINE_COLORS["Ot"];
    const isSingleDrink = drinks.length === 1;

    // add self as similar to self, if not already present. Prepend to the list.
    const self_similar = drink_style.similar.find((sim) => sim[0] === drink_style.id);
    if (!self_similar) {
      drink_style.similar.unshift([drink_style.id, 1.0]);
    }

    const addTrait = (key, isMember, pri=0, val=0) => {
      if (traits.find((trait) => trait.name === key)) return;

      const is_icon = ["Adventuresome", "Organic", "Biodynamic"].includes(key);

      if (!isSingleDrink && isMember && !is_icon) {
        // only propagate icons to the top of a multi-member group.
        return;
      }

      if (key === "Alcohol" && val>0) {
        key = formatABV(val);
      }

      traits.push({name: key, priority: pri + (is_icon ? 10 : 0), is_icon: is_icon, is_member: isMember});

      if (key === "Adventuresome") meta.isAdventuresome = true;
      if (key === "Organic") meta.isOrganic = true;
      if (key === "Biodynamic") meta.isBiodynamic = true;
    };

    // build icon properties i.e. Sp, De, Re, etc.
    if (drink_style.subtypes.length) {
      style_icon_text = drink_style.subtypes[0].slice(0, 2);
      if (["Sw", "Fo"].includes(style_icon_text)) style_icon_text = "De";

      const isSparkling = drink_style.subtypes.includes("Sparkling");
      const isDessert = drink_style.subtypes.includes("Sweet");

      if (isDessert) {
        style_icon_text = "De";
        addTrait("Dessert", false, 2);
      }
      if (isSparkling) style_icon_text = "Sp";

      if (drink_style.subtypes.includes("Red")) style_icon_bgcolor = WINE_COLORS.Re;
      if (drink_style.subtypes.includes("White")) style_icon_bgcolor = WINE_COLORS.Wh;
      if (drink_style.subtypes.includes("Orange"))
        style_icon_bgcolor = WINE_COLORS.Or;
      if (drink_style.subtypes.includes("Rosé")) style_icon_bgcolor = WINE_COLORS.Ro;

      style_icon_text_color =
        style_icon_bgcolor === "#292320" ? "#ffffff" : "#352B2E";
    }

    // build aspects string i.e. "Red Fruits, Herbs, Earth"
    if (drink_style.descriptors && drink_style.descriptors.aspects) {
      drink_style.descriptors.aspects.forEach((aspect) => {
        aspects_str += aspect + ", ";
      });

      aspects_str = aspects_str.slice(0, -2);
    }

    // parse drink_style for attributes and style for traits.
    if (drink_style.descriptors && drink_style.descriptors.style) {
      addTrait(drink_style.descriptors.style, false, 0);
    }

    Object.keys(drink_style.attributes).map((key) => addTrait(key, false, 5, drink_style.attributes[key]));

    // surface individual drink data to the category level
    // - calculate the min/max glass & bottle prices
    // - determine if the drink_style (category) has glass or bottle size options.
    drinks.forEach((drink) => {
      // parse drink for additional traits.
      Object.keys(drink.attributes).map((key) => addTrait(key, true, 5, drink.attributes[key]));

      if (isSingleDrink && uiConfig.hoist_traits) {
        // for hoisting, surface the region/variety information
        const full_name = fullDrinkName(drink);

        if (drink.region && !(full_name.includes(drink.region))) {
          // if the country name is not the same as the region name, show both.
          if (drink.country && drink.country.name !== drink.region) {
            addTrait(`${drink.region}, ${drink.country['iso-alpha-2']}`, true, 2);
          } else {
            addTrait(drink.region, true, 2);
          }
        }
        if (drink.varieties) {
          const varieties = drink.varieties.join(', ');
          if (!(full_name.includes(varieties))) {
            addTrait(varieties, true, 3);
          }
        }
      }

      drink.formats.forEach((format) => {
        const price = format[1];

        if (format[0] !== GLASS_SIZE) {
          meta.hasBottleOption = true;

          // calculate bottle prices
          if (price > meta.bottlePriceMax) meta.bottlePriceMax = price;
          if (price < meta.bottlePriceMin) meta.bottlePriceMin = price;

          // determine the price range (low, med, high) based on bottle price.
          if (price >= 150) {
            meta.isHighPrice = true;
          } else if (price >= 75 && price < 150) {
            meta.isMedPrice = true;
          } else if (price < 75) {
            meta.isLowPrice = true;
          }
        }

        if (format[0] === GLASS_SIZE) {
          meta.hasGlassOption = true;

          // calculate glass prices
          if (price > meta.glassPriceMax) meta.glassPriceMax = price;
          if (price < meta.glassPriceMin) meta.glassPriceMin = price;
        }
      });
    });

    // remove prices if size option is not available.
    if (meta.bottlePriceMax === 0) delete meta.bottlePriceMax;
    if (meta.glassPriceMax === 0) delete meta.glassPriceMax;
    if (meta.bottlePriceMin === 99999) delete meta.bottlePriceMin;
    if (meta.glassPriceMin === 99999) delete meta.glassPriceMin;

    // additional price meta data
    if (
      meta.glassPriceMin &&
      meta.glassPriceMax &&
      meta.glassPriceMax > meta.glassPriceMin
    ) {
      meta.hasRangedGlassPrices = true;
    }

    if (
      meta.bottlePriceMin &&
      meta.bottlePriceMax &&
      meta.bottlePriceMax > meta.bottlePriceMin
    ) {
      meta.hasRangedBottlePrices = true;
    }

    // sort the traits and member_traits by priority
    traits.sort((a, b) => a.priority - b.priority);

    return {
      ...drink_style,
      traits,
      aspects_str,
      style_icon_text,
      style_icon_text_color,
      style_icon_bgcolor,
      subtypes: formatDrinkSubtypes(drink_style),
      meta,
    };
  });

  return {
    ...dataToTransform,
    drink_styles,
    drink_list: dataToTransform.drink_list.map((drink) => {
      const { abbreviation, menu_name, menu_name_clean, pair_table_name } =
        generateDrinkNames(drink);

      return {
        ...drink,
        abbreviation,
        menu_name,
        menu_name_clean,
        pair_table_name,
        meta: generateDrinkMetaData(drink),
      };
    }),
    layout: dataToTransform.layout,
    servers: dataToTransform.servers
  };
};
