import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

defineCustomElements(window);

ReactDOM.render(<App />, document.getElementById("root"));

const refreshCacheAndReload = () => {
  // Clear cache and reload window to get latest app version
  caches.keys().then((names) => {
    for (const name of names) {
      caches.delete(name);
    }
  });
  window.location.reload();
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  //   onSuccess: () => console.log("Service worker successfully updated"),
  onUpdate: (reg) => {
    console.log("Update available for Service Worker - automatically updating");
    refreshCacheAndReload();
  },
});
