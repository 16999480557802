import React from "react";
import { IonButton } from "@ionic/react";

import "./FilterButton.css";

interface FilterButtonProps {
  icon: string;
  alt: string;
  active: boolean;
  onClick: () => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  icon,
  alt,
  active,
  onClick,
}) => (
  <IonButton
    className={`filterBtn ${active ? "active-toggle" : ""}`}
    fill="outline"
    size="small"
    onClick={onClick}
  >
    <img src={icon} alt={alt} />
  </IonButton>
);

export default FilterButton;
