import React, { useEffect, useState } from "react";
import cx from "classnames";
import useScreenOrientation from "react-hook-screen-orientation";
import { useGlobalContext } from "../../GlobalContext";
import { IonButton, IonContent } from "@ionic/react";
import OrderPanel from "../OrderPanel";
import SortToggles from "../SortToggles";
import PairTable from "../PairTable";

import iconEditOrder from "../../assets/icon-edit-order.svg";

import "./PairView.css";

const PairView: React.FC = () => {
  const { orders, tables } = useGlobalContext();

  const screenOrientation = useScreenOrientation();

  const [activeTableName, setActiveTableName] = useState<string>("");
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  useEffect(() => {
    const activeTable = tables.find((o) => o.active);
    if (activeTable && activeTableName !== activeTable.name) {
      setActiveTableName(activeTable.name);
      setActiveFilters([]);
    }
  }, [tables, activeTableName]);

  return (
    <div className="pv-layout">
      <OrderPanel />
      <div style={{ flexGrow: 1 }}>
        {orders.filter((o) => o.selected).length > 0 ? (
          <>
            <SortToggles
              activeFilters={activeFilters}
              setActiveFilters={(filters: string[]) =>
                setActiveFilters(filters)
              }
            />
            <IonContent
              className={cx(
                "pv-ion-content",
                screenOrientation === "landscape-primary" &&
                  "pv-ion-content-landscape"
              )}
            >
              <PairTable activeFilters={activeFilters} />
            </IonContent>
          </>
        ) : (
          <div className="table-prompt">
            <h2 className="message-header" style={{ marginTop: "6.25rem" }}>
              No items added to table
            </h2>
            <p className="message-body">
              Add items from the menu to get drink suggestions
            </p>
            <IonButton class="edit-order-btn" routerLink="/menu" fill="clear">
              <img src={iconEditOrder} alt="edit order" />
            </IonButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default PairView;
