import React, { useEffect, useState } from "react";

import {
  IonList,
  IonLabel,
  IonItem,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonButton,
  IonToolbar,
  IonCol,
  IonGrid,
  IonRow,
  CreateAnimation,
  IonToast,
} from "@ionic/react";

import "./OrderView.css";

import { MenuItem, Table, useGlobalContext } from "../../GlobalContext";
import { archiveTable, remove, set, getTableKey } from "../../data/IonicStorage";
import useWindowDimensions from "../../hooks/WindowDimensions";

const OrderView: React.FC = () => {
  const { orders, setOrders, tables, setTables, user, data} =
    useGlobalContext();
  const { width } = useWindowDimensions();

  const [tab, setTab] = useState({ name: "Current", index: 0 });
  const [currentOrder, setCurrentOrder] = useState<MenuItem[]>([]);
  const [showResetToast, setShowResetToast] = useState(false);

  useEffect(() => {
    setCurrentOrder(
      orders.map((item) => {
        return { ...item, checked: true };
      })
    );
  }, [orders]);

  const getAnimationFromTo = () => {
    if (width <= 700) {
      return [
        {
          property: "transform",
          fromValue: "translateX(400px)",
          toValue: "translateX(0px)",
        },
      ];
    } else {
      return [
        {
          property: "transform",
          fromValue: "translateY(400px)",
          toValue: "translateY(0px)",
        },
      ];
    }
  };

  var activeTableName = tables.find((table) => table.active)?.name;

  const activeTabToSection = (tab: string) => {
    return tab;
  };

  const renderMenuItems = (activeTab: string, activeTabIndex: number) => {
    switch (activeTab) {
      case "Current":
        if (currentOrder.length > 0) {
          return (
            <IonList class="item-list">
              {currentOrder?.map((item, index) => {
                return (
                  <IonItem
                    key={"current-" + index}
                    lines="none"
                    class="checkbox-label"
                  >
                    <IonLabel>{item.name}</IonLabel>
                    <IonCheckbox
                      mode="md"
                      class="checkbox"
                      slot="start"
                      checked={item.checked}
                      onClick={() =>
                        handleChange(
                          item.id,
                          item.name,
                          item.abbreviation,
                          activeTab
                        )
                      }
                    ></IonCheckbox>
                  </IonItem>
                );
              })}
            </IonList>
          );
        } else {
          return (
            <div className="empty-order">
              <h2 className="empty-order-header">Order is empty</h2>
              <p className="empty-order-body">
                Menu items already added to the table will be listed here for
                easy access.
              </p>
            </div>
          );
        }
      case "Wines":
        return (
          <IonList class="item-list">
            {data.drink_list
              .sort((a, b) => (a.menu_name_clean < b.menu_name_clean ? -1 : 1))
              .map((drink, index) => {
                return (
                  <IonItem
                    key={"drinks-" + index}
                    lines="none"
                    class="checkbox-label"
                  >
                    <IonLabel>{drink.menu_name}</IonLabel>
                    <IonCheckbox
                      onClick={() =>
                        handleChange(
                          drink.id,
                          drink.menu_name,
                          drink.abbreviation,
                          activeTab,
                          drink.formats[0][2] as string, // bin number
                          drink.formats[0][0] as string  // format
                        )
                      }
                      mode="md"
                      class="checkbox"
                      slot="start"
                      checked={currentOrder.some((item) => {
                        return drink.id === item.id && item.checked;
                      })}
                    ></IonCheckbox>
                  </IonItem>
                );
              })}
          </IonList>
        );
      default:
        return (
          <IonList class="item-list">
            {Object.keys(data.menu[activeTabIndex].dishes).map((key, index) => {
              var dish =
                data.menu[activeTabIndex].dishes[key as unknown as number];

              return (
                <IonItem
                  key={activeTab + index}
                  lines="none"
                  class="checkbox-label"
                >
                  <IonLabel>
                    {dish.name} {dish.flags.includes("Special") ? "★" : null}
                  </IonLabel>
                  <IonCheckbox
                    onClick={() =>
                      handleChange(
                        dish.id,
                        dish.name,
                        dish.abbreviation,
                        activeTabToSection(activeTab)
                      )
                    }
                    mode="md"
                    class="checkbox"
                    slot="start"
                    checked={currentOrder.some((item) => {
                      return dish.id === (item.id as string) && item.checked;
                    })}
                  ></IonCheckbox>
                </IonItem>
              );
            })}
          </IonList>
        );
    }
  };

  const handleChange = (
    id: string,
    name: string,
    abbreviation: string,
    section: string,
    bin?: string,
    format?: string
  ) => {
    const existingItem = currentOrder.find((item) => {
      return id === (item.id as string);
    });

    var updatedOrder = [];

    if (existingItem) {
      if (existingItem.saved) {
        // Show saved items as unchecked
        updatedOrder = currentOrder.map((item) => {
          if (id === item.id) {
            const updatedItem = {
              ...item,
              checked: !item.checked,
            };
            return updatedItem;
          }
          return item;
        });
      } else {
        // Delete item from list if not previously saved
        updatedOrder = currentOrder.filter((item) => item.id !== id);
      }
    } else {
      // Add to current order list
      updatedOrder = currentOrder.concat({
        id: id,
        name: name,
        abbreviation: abbreviation,
        section: section,
        selected: true,
        checked: true,
        saved: false,
        bin: bin,
        format: format,
        type: "menu",
      });
    }

    setCurrentOrder(updatedOrder);
  };

  const saveOrder = () => {
    // Save order in current order tab
    // Remove any unselected orders from being saved, and set all items in list as saved
    const savedOrder = currentOrder
      .filter((item) => item.checked)
      .map((item) => {
        return { ...item, saved: true };
      });

    set(getTableKey(activeTableName), savedOrder);
    setOrders(savedOrder);
  };

  const resetOrder = async () => {
    const currentTimestamp = new Date().getTime();
    var resetTable = tables.find((table) => table.active) as unknown as Table;
    resetTable = {
      ...resetTable,
      state: "reset",
      updatedTimestamp: currentTimestamp,
    };

    const updatedTables = tables.map((t) => {
      if (t.name === activeTableName) {
        return {
          ...t,
          createdTimestamp: currentTimestamp,
          updatedTimestamp: currentTimestamp,
        };
      }
      return t;
    });

    await archiveTable(user, resetTable, orders);
    await set(getTableKey(activeTableName), []);
    await set("tables", updatedTables);
    setOrders([]);
    setCurrentOrder([]);
    setTables(updatedTables);
    setShowResetToast(true);
  };

  const closeOrder = async () => {
    var closedTable = tables.find((table) => table.active) as unknown as Table;
    closedTable = {
      ...closedTable,
      state: "closed",
      updatedTimestamp: new Date().getTime(),
    };

    await archiveTable(user, closedTable, orders);
    setOrders([]);
    setCurrentOrder([]);

    // Remove active table from list
    const updatedTables = tables.filter((table) => !table.active);

    // Set next table in list as active
    if (updatedTables.length > 0) {
      updatedTables[0] = { ...updatedTables[0], active: true };
    }
    setTables(updatedTables);

    // Remove table and order details from storage
    await set("tables", updatedTables);
    await remove(getTableKey(activeTableName));
  };

  return (
    <CreateAnimation duration={300} play={true} fromTo={getAnimationFromTo()}>
      <div className="order-drawer-header">
        <IonGrid>
          <IonRow>
            <IonCol size="6" class="order-profile">
              <span className="active-table">{activeTableName}</span>
            </IonCol>
            <IonCol size="6">
              <div className="menu-table-buttons">
                <IonButton
                  routerLink="/pairing"
                  fill="clear"
                  class="menu-close"
                  onClick={() => closeOrder()}
                >
                  Close table
                </IonButton>
                <IonButton
                  routerLink="/menu"
                  fill="clear"
                  class="menu-reset"
                  onClick={() => resetOrder()}
                >
                  Reset table
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>

      <IonContent>
        <div className="fixed-content">
          <div className="content-drawer">
            <div className="tab-column">
              <IonButton
                key="tab-current"
                onClick={() => setTab({ name: "Current", index: 0 })}
                className={`tab ${tab.name === "Current" ? "active-tab" : ""}`}
                fill="clear"
                expand="full"
              >
                <span className="tab-name">Current Order</span>
              </IonButton>

              <IonButton
                key="tab-drinks"
                onClick={() => setTab({ name: "Wines", index: 0 })}
                className={`tab ${tab.name === "Wines" ? "active-tab" : ""}`}
                fill="clear"
                expand="full"
              >
                <span className="tab-name">Wines</span>
              </IonButton>

              {Object.keys(data.menu).map((key, index) => {
                var name = data.menu[key as unknown as number].section;
                return (
                  <IonButton
                    key={"tab-" + index}
                    onClick={() =>
                      setTab({
                        name: name,
                        index: key as unknown as number,
                      })
                    }
                    fill="clear"
                    expand="full"
                    className={`tab ${tab.name === name ? "active-tab" : ""}`}
                  >
                    <span className="tab-name">{name}</span>
                  </IonButton>
                );
              })}
            </div>

            <div className="values-column">
              <div>{renderMenuItems(tab.name, tab.index)}</div>
            </div>
          </div>
        </div>
      </IonContent>

      <IonFooter class="drawer-footer">
        <IonToolbar class="drawer-footer-toolbar">
          <IonButton
            fill="outline"
            class="footer-buttons cancel"
            routerLink="/pairing"
          >
            Cancel
          </IonButton>
          <IonButton
            type="submit"
            class="footer-buttons save"
            routerLink="/pairing"
            onClick={() => saveOrder()}
          >
            Save
          </IonButton>
        </IonToolbar>
      </IonFooter>

      <IonToast
        isOpen={showResetToast}
        onDidDismiss={() => setShowResetToast(false)}
        message="Table and menu items have been reset."
        duration={10000}
        position="bottom"
        cssClass="reset-toast"
        keyboardClose={true}
        buttons={[
          {
            text: "Done",
            role: "cancel",
          },
        ]}
      />
    </CreateAnimation>
  );
};

export default OrderView;
