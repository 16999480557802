import React from "react";
import { useGlobalContext } from "../../GlobalContext";
import "./SuggestionDetail.css";

import iconAddItem from "../../assets/icon-add-item.svg";
import iconCircleCheck from "../../assets/icon-circle-check.svg";
import iconOrganic from "../../assets/icon-organic.svg";
import iconAdventuresome from "../../assets/icon-adventuresome.svg";
import { GLASS_SIZE } from "../../data/utils";
import {formatABV} from "../PairView/helpers";

interface SuggestionDetailProps {
  drink_style: any;
  drinks: any[];
  addDrinkToOrder: (drinks: any[], size?: string) => void;
  hoist: boolean;
}

const SuggestionDetail: React.FC<SuggestionDetailProps> = ({
  drink_style,
  drinks,
  addDrinkToOrder,
  hoist
}) => {
  const { orders , uiConfig} = useGlobalContext();
  const isSingleDrink = drinks.length === 1;

  return (
    <>
      {drink_style.aspects_str && (
        <div className="info">
          <div className="aspects">
            <div className="label">Aspects</div>
            <div>{drink_style.aspects_str}</div>
          </div>
        </div>
      )}

      <div>
        {drinks
          .sort((a, b) => (a.meta.bottle_price > b.meta.bottle_price ? 1 : -1))
          .map((drink, i) => {
            let priceBottle = "NA";
            let priceGlass = "NA";

            if (drink.formats) {
              drink.formats.forEach((arr: any) => {
                if (arr[0] !== GLASS_SIZE) {
                    priceBottle = arr[1];
                }
                else {
                    priceGlass = arr[1];
                }
              });
            }

            const existingItem = orders.find((item) => {
              return drink.id === (item.id as string);
            });

            const traits: string[] = [];
            const nameShown = uiConfig.hoist && isSingleDrink ? drink.menu_name : drink_style.name;

            if (drink.region && !(nameShown.includes(drink.region))) {
                // if the country name is not the same as the region name, show both.
                if (drink.country && drink.country.name !== drink.region) {
                    traits.push(`${drink.region}, ${drink.country['iso-alpha-2']}`);
                }
                else {
                  traits.push(drink.region);
                }
            }
            if (drink.varieties) {
              const varieties = drink.varieties.join(', ');
              if (!(nameShown.includes(varieties))) {
                  traits.push(varieties);
              }
            }

            // show bin number first if it is available.
            if (drink.meta.bottle_bin) traits.push(drink.meta.bottle_bin);

            const attributes = Object.keys(drink.attributes);
            attributes.forEach((key) => {
              if (key === "Alcohol" && drink.attributes[key] > 0) {
                // truncate alcohol percentage to maximum 1 decimal place.
                // if the alcohol percentage is a whole number, don't show the decimal.
                traits.push(formatABV(drink.attributes[key]));
              }
              else if (key !== "Adventuresome" && key !== "Organic") {
                traits.push(key);
              }
            });

            // Always show leaf icon at the end of the traits list.
            if (
              attributes.includes("Organic") ||
              attributes.includes("Biodynamic")
            ) {
              traits.push("Organic");
            }

            // In the event that a drink is both organic and biodynamic, we only want
            // to show a single leaf icon.
            let isDisplayingLeafIcon = false;

            return (
              <div
                className="suggestion-detail-row"
                key={i}
                onClick={() => {
                  if (!existingItem) addDrinkToOrder(drink);
                }}
              >
                <div>
                  <img
                    src={existingItem ? iconCircleCheck : iconAddItem}
                    alt="add item"
                  />
                </div>
                <div>
                  <div className="drink-name">
                  {
                    hoist ? drink.menu_name : drink.pair_table_name
                  }
                  </div>
                  <div className="pt-traits">
                    <ul>
                      {traits.map((trait, index) => {
                        if (trait === "Adventuresome") {
                          return (
                            <img
                              key={index}
                              src={iconAdventuresome}
                              alt="add item"
                            />
                          );
                        }

                        if (
                          (trait === "Organic" || trait === "Biodynamic") &&
                          !isDisplayingLeafIcon
                        ) {
                          isDisplayingLeafIcon = true;
                          return (
                            <img key={index} src={iconOrganic} alt="add item" />
                          );
                        }

                        if (trait !== "Organic" && trait !== "Biodynamic") {
                          return <li key={index}> {trait}</li>;
                        }

                        return null;
                      })}
                    </ul>
                  </div>
                </div>
                <div>
                  <div className="pt-prices">
                    <div
                      style={{
                        color: priceGlass === "NA" ? "#727272" : "#F2F2F2",
                      }}
                    >
                      {priceGlass}
                    </div>
                    <div
                      style={{
                        color: priceBottle === "NA" ? "#727272" : "#F2F2F2",
                      }}
                    >
                      {priceBottle}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default SuggestionDetail;
