import { Storage, Drivers } from "@ionic/storage";

var storage = false;

export const createStore = (name = "awesommdb") => {
  storage = new Storage({
    name,
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
  });

  storage.create();
};

export const set = async (key, val) => {
  await storage.set(key, val);
};

export const get = async (key) => {
  const val = await storage.get(key);
  return val;
};

export const remove = async (key) => {
  await storage.remove(key);
};

export const clear = async () => {
  await storage.clear();
};

/*
 * getTableKey - prefix table names so that tables have a dedicated
 * namespace within the database
 */
export const getTableKey = (table) => {
    return `table-${table}`;
}

export const archiveTable = async (activeUser, activeTable, orders) => {
  var archivedData = await get("archive");

  if (archivedData) {
    if (archivedData[archivedData.length - 1].name === activeUser.name) {
      // Add to current user's list if they're the last active user
      archivedData = archivedData.map((user) => {
        if (user.name === activeUser.name) {
          const table = { ...activeTable, orders };

          return { ...user, tables: user.tables.concat(table) };
        }
        return user;
      });
    } else {
      // Add new user entry if not the latest active user
      const userWithData = {
        ...activeUser,
        tables: [{ ...activeTable, orders }],
      };
      archivedData = archivedData.concat(userWithData);
    }
  } else {
    const table = { ...activeTable, orders };
    archivedData = [{ ...activeUser, tables: [table] }];
  }

  await set("archive", archivedData);
};
