import React from "react";
import cx from "classnames";
import { RankedDrinkStyle } from "../PairView/helpers";

import iconGlass from "../../assets/icon-glass.svg";
import iconBottle from "../../assets/icon-bottle.svg";

import "./PairTableHeader.css";

interface PairTableHeaderProps {
  results: RankedDrinkStyle[];
  screenOrientation: string;
}

const PairTableHeader: React.FC<PairTableHeaderProps> = ({
  results = [],
  screenOrientation = "portrait-primary",
}) => (
  <div className="pt-suggestions">
    <span>
      {results.length} Suggestion{results.length === 1 ? "" : "s"}
    </span>
    <div
      className={cx(
        "pt-icons",
        screenOrientation === "landscape-primary" && "pt-icons-landscape"
      )}
    >
      <img src={iconGlass} alt="add table" />
      <img src={iconBottle} alt="add table" />
    </div>
  </div>
);

export default PairTableHeader;
