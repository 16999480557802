import React, { useState } from "react";
import {
  CreateAnimation,
  IonButton,
  IonItem,
  IonList,
  IonToast,
} from "@ionic/react";

import "./ProfileSelector.css";
import { useGlobalContext, User } from "../../GlobalContext";
import { archiveTable, get, remove, set, getTableKey } from "../../data/IonicStorage.js";
import iconExpand from "../../assets/icon-expand.svg";
import iconCheckmark from "../../assets/icon-checkmark.svg";

import appPackage from "../../../package.json";
import { uploadAppData } from "../../api/DataApi";

const ProfileSelector: React.FC = () => {
  const { user, setUser, tables, setTables, setOrders, data, resto } = useGlobalContext();

  const [showPanel, setShowPanel] = useState(false);
  const [showUploadToast, setShowUploadToast] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");

  const setActiveUser = async (newUser: User) => {
    // Update local storage - archive and reset tables and orders
    for (let table of tables) {
      const orders = await get(getTableKey(table.name));
      await archiveTable(
        user,
        {
          ...table,
          state: "profileChange",
          updatedTimestamp: new Date().getTime(),
        },
        orders
      );
      await remove(getTableKey(table.name));
    }

    const timestampedUser = {
      ...newUser,
      profileActivatedTimestamp: new Date().getTime(),
    };

    await set("tables", []);
    await set("activeUser", timestampedUser);

    // Update global context
    setUser(timestampedUser);
    setTables([]);
    setOrders([]);
  };

  const uploadArchivedData = async () => {
    // Upload all data currently archived to API
    const uploadData = await get("archive");

    if (uploadData === null) {
      setUploadMessage("No data to upload. Close or reset existing tables.");
      setShowUploadToast(true);
    }

    console.log(`resto is ${resto}`);
    const uploadStatus = await uploadAppData(resto, uploadData);

    if (uploadStatus) {
      setUploadMessage("Data successfully uploaded.");
      setShowUploadToast(true);

      // Delete current archive since has been uploaded
      remove("archive");
    } else {
      setUploadMessage("Data failed to upload. Please try again later.");
      setShowUploadToast(true);
    }
  };

  return (
    <>
      <div className="profile" style={{ backgroundColor: user.color }}>
        <IonButton
          class="profile-select"
          fill="clear"
          onClick={() => setShowPanel(true)}
          expand="block"
        >
          {user.abbrev}
          <img slot="end" src={iconExpand} alt="expand" />
        </IonButton>
      </div>

      {showPanel ? (
        <CreateAnimation
          duration={300}
          play={true}
          fromTo={[
            {
              property: "transform",
              fromValue: "translateX(-200px)",
              toValue: "translateX(0px)",
            },
          ]}
        >
          <div className="profile-select-panel">
            <div className="active-profile">
              <IonItem key="active-profile" lines="none" class="user-label">
                <div
                  className="profile-icon"
                  slot="start"
                  style={{ backgroundColor: user.color }}
                >
                  {user.abbrev}
                </div>
                {user.name}
                <img slot="end" src={iconCheckmark} alt="active profile" />
              </IonItem>
            </div>
            <IonList class="profile-select-list">
              {data.servers
                .filter((u) => u.name !== user.name)
                .map((user, index) => {
                  return (
                    <IonItem
                      button
                      key={"profile-" + index}
                      lines="none"
                      class="user-label"
                      onClick={() => {
                        setActiveUser(user);
                        setShowPanel(false);
                      }}
                    >
                      <div
                        className="profile-icon"
                        slot="start"
                        style={{ backgroundColor: user.color }}
                      >
                        {user.abbrev}
                      </div>
                      {user.name}
                    </IonItem>
                  );
                })}
            </IonList>
            <div className="version-label">
              API v{data.api_version}, App v{appPackage.version}
            </div>
            <div className="upload-section">
              <IonButton
                fill="outline"
                size="small"
                class="footer-buttons cancel"
                routerLink="/pairing"
                onClick={() => uploadArchivedData()}
              >
                Upload data
              </IonButton>
            </div>
          </div>
          <div
            className="profile-overlay"
            onClick={() => setShowPanel(false)}
          ></div>

          <IonToast
            isOpen={showUploadToast}
            onDidDismiss={() => setShowUploadToast(false)}
            message={uploadMessage}
            duration={20000}
            position="bottom"
            cssClass="reset-toast"
            keyboardClose={true}
            buttons={[
              {
                text: "Done",
                role: "cancel",
              },
            ]}
          />
        </CreateAnimation>
      ) : null}
    </>
  );
};

export default ProfileSelector;
